<template>
  <section class="content-banner">
    <div class="content-banner-itens">
      <PuSkeleton
        v-if="loading"
        height="400px"
        class="content-banner-itens-image"
      />
      <div
        v-else
        class="content-banner-itens-image"
        :style="`background: url('` + banner + `')`"
      ></div>
      <!-- <div class="content-banner-itens-text">
        <div class="content-banner-itens-text-title">
          <h2>Promoção<span>Nome da promoção</span></h2>
          <h2 class="price">
            <span>R$</span>
            130,00
          </h2>
        </div>
        <button class="btn-cotation-default">CONFIRA!</button>
      </div> -->
    </div>
  </section>
</template>

<script>
import Site from "@/api/Site";
import { getThemeActive } from "@/LayoutTheme";

export default {
  props: {
    pageLocal: String,
  },
  components: {},
  async mounted() {
    this.banner = getThemeActive().images[this.pageLocal];
    this.loading = true;
    let pageLocal = this.pageLocal;

    if(pageLocal in this.pageNames){
      pageLocal = this.pageNames[pageLocal];
    }
    
    let fullUrl = document.location.protocol+'//'+document.location.hostname+''+this.banner;

    let params = {
      type: "file",
      file: fullUrl,
      identifier: "Banner Pagina",
      local: pageLocal,
    };

    await Site.getContent(params).then((response) => {
      let data = response.data.data;
      this.banner = data;
      this.loading = false;
    });
  },
  data() {
    return {
      tabMenuActive: "new",
      banner: null,
      loading: false,
      pageNames : {
        'vehicleSchedule': 'vehicle_schedule',
        'vehicleParts': 'vehicle_parts',
        'vehicleConsortium': 'vehicle_consortium',
        'directSales': 'direct_sales',
        'vehicleTestDrive': 'vehicle_test_drive'
      }
    };
  },
};
</script>

<style lang="scss">
.content-banner {
  &-itens {
    position: relative;
    &-image {
      height: 740px;
      width: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media (min-width: 992px) and (max-height: 800px) {
        height: 500px;
      }
      @media (max-width: 991px) {
        height: 400px;
      }
    }
    &-text {
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 991px) {
        left: 50%;
        transform: translateX(-50%);
        top: initial;
        bottom: 30px;
        width: 100%;
        max-width: 400px;
        padding: 0px 15px;
      }
      &-title {
        background: rgba(12, 39, 67, 0.75);
        border: 1px solid var(--white);
        border-radius: 10px;
        padding: 15px 30px;
        margin-bottom: 17px;
        text-align: left;
        @media (max-width: 991px) {
          text-align: center;
          margin-bottom: 25px;
        }
        h2 {
          font-size: 60px;
          color: var(--white);
          margin-bottom: 0;
          font-family: "Font Bold";
          line-height: 1;
          @media (max-width: 991px) {
            font-size: 40px;
          }
          @media (max-width: 359px) {
            font-size: 30px;
          }
          span {
            display: block;
            font-family: "Font Medium";
            font-size: 30px;
            @media (max-width: 359px) {
              font-size: 24px;
            }
          }
        }
        .price {
          display: flex;
          align-items: flex-start;
          font-size: 60px;
          font-family: "Font Black";
          color: var(--white);
          margin-bottom: 0;
          margin-top: 20px;
          @media (max-width: 991px) {
            font-size: 40px;
            justify-content: center;
          }
        }
      }
      .btn-cotation-default {
        font-size: 18px;
        @media (max-width: 991px) {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
